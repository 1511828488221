import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import globalConst from '../../constants';
import { loadAuth } from '../../redux/modules/auth';
import { getLangFromUrl } from '../../services/translateSelector';
import { getLocale, load as loadTranslates, } from '../../redux/modules/localization';
import RouterContainer from './RouterContainer';
import NotFoundPage from '../NotFoundPage';

const LanguageContainer = (props) => {
  const { store } = props;
  const history = createBrowserHistory();

  const [loading, setLoading] = useState(true);
  const [locale, setLocale] = useState('');
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    async function initState() {
      await store.dispatch(loadAuth());
      setLoading(false);

      // [1] - get lang from url for load translations
      const langFromUrl = getLangFromUrl(history.location.pathname);
      if (!langFromUrl) {
        const langFromStorage = getLocale();
        await store.dispatch(loadTranslates(langFromStorage));
        await setLocale(langFromStorage);
        return;
      }

      // [3] - if langFromUrl right
      if (globalConst.LANGUAGES_LIST.includes(langFromUrl)) {
        await store.dispatch(loadTranslates(langFromUrl));
        await setLocale(langFromUrl);
        return;
      }

      await store.dispatch(loadTranslates());
      setNotFound(true);
    }

    initState();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        {globalConst.LANGUAGES_ROUTES_LIST.map((path) => (
          <Route
            key={path}
            path={`${path}/*`}
            element={<RouterContainer />}
          />
        ))}
        {locale && <Route path="/" element={(<Navigate to={`/${locale}`} replace />)} />}
        {notFound && (
          <Route
            path="*"
            element={(
              <NotFoundPage
                currentLang="en"
              />
            )}
          />
        )}
      </Routes>
    </BrowserRouter>
  );
};

LanguageContainer.propTypes = {
  store: PropTypes.object.isRequired,
};

export default LanguageContainer;