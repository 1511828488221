import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import {
  getCurrentLang,
  getAvailableLangs,
  getTranslates,
} from '../../services/translateSelector';
import {
  getProfile,
  getUserType,
  getIsExternal,
  getIsExternalBlocked,
  getIsInternalBlocked,
  getIsInternalBlockedSwzzorg,
  getIsInternalBlockedFletcher,
  getIsYzorgManagers,
  getExternalExcellentFlex,
} from '../../services/selectors';
import App from '../App';
import AgreementsPage from '../AgreementsPage';
import NotFoundPage from '../NotFoundPage';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';
import ForgotPWDConfirmPage from '../ForgotPWDConfirmPage';
import ForgotWorkerPWDConfirmPage from '../ForgotWorkerPWDConfirmPage';
import ConfirmationPage from '../ConfirmationPage';
import ErrorBoundary from '../../components/ErrorBoundary';
import LoginPage from '../LoginPage';
import SignupPage from '../SignupPage';
import ForgotPWDPage from '../ForgotPWDPage';
import ManagerInvitation from '../ManagersPage/ManagerInvitation';
import DashboardPage from '../DashboardPage';
import ShiftCreatePage from '../ShiftCreatePage';
import ShiftDetailPage from '../ShiftDetailPage';
import GigImportPage from '../GigImportPage';
import GigMultiplePage from '../GigMultiplePage';
import ManagerPage from '../ManagerPage';
import ManagersPage from '../ManagersPage';
import MyProfile from '../MyProfile';
import OrganizationsPage from '../OrganizationsPage';
import OrganizationsEnrichPage from '../OrganizationsEnrichPage';
import OrganizationsImportPage from '../OrganizationsImportPage';
import OrganizationPage from '../OrganizationPage';
import PlanningPage from '../PlanningPage';
import ReviewPage from '../ReviewPage';
import ReportsPage from '../ReportsPage';
import SettingsMainPage from '../SettingsMainPage';
import ProjectsPage from '../ProjectsPage';
import ProjectCreatePage from '../ProjectPage/ProjectCreatePage';
import ProjectDetailPage from '../ProjectPage/ProjectDetailPage';
import TimeTrackerPage from '../TimeTrackerPage';
import WorkerPage from '../WorkerPage';
import WorkerCalendar from '../WorkerCalendar';
import WorkersPage from '../WorkersPage';
import WorkersInvitePage from '../WorkersInvitePage';
import WorkersImportPage from '../WorkersImportPage';
import WorkersEnrichPage from '../WorkersEnrichPage';
import BillingOverviewPage from '../BillingOverviewPage';

const mapStateToProps = (state) => ({
  currentLang: getCurrentLang(state),
  translates: getTranslates(state),
  langs: getAvailableLangs(state),
  profile: getProfile(state),
  userType: getUserType(state),
  isExternal: getIsExternal(state),
  isExternalBlocked: getIsExternalBlocked(state),
  isInternalBlocked: getIsInternalBlocked(state),
  isInternalBlockedFletcher: getIsInternalBlockedFletcher(state),
  isInternalBlockedSwzzorg: getIsInternalBlockedSwzzorg(state),
  isExternalExcellentFlex: getExternalExcellentFlex(state),
  isYzorgManagers: getIsYzorgManagers(state),
});

class RouterContainer extends PureComponent {
  getChildContext() {
    return {
      localization: this.props.translates,
      currentLang: this.props.currentLang,
      langs: this.props.langs,
      profile: this.props.profile,
      userType: this.props.userType,
      isExternal: this.props.isExternal,
      isExternalBlocked: this.props.isExternalBlocked,
      isInternalBlocked: this.props.isInternalBlocked,
      isInternalBlockedFletcher: this.props.isInternalBlockedFletcher,
      isInternalBlockedSwzzorg: this.props.isInternalBlockedSwzzorg,
      isExternalExcellentFlex: this.props.isExternalExcellentFlex,
      isYzorgManagers: this.props.isYzorgManagers,
    };
  }

  render() {
    const {
      currentLang,
      userType,
      profile,
    } = this.props;

    const protectedRouters = [
      { path: 'billing', element: <BillingOverviewPage /> },
      { path: 'dashboard', element: <DashboardPage /> },
      { path: 'gig/import', element: <GigImportPage /> },
      { path: 'gigs', element: <GigMultiplePage /> },
      { path: 'managers', element: <ManagersPage /> },
      { path: 'manager/:id', element: <ManagerPage /> },
      { path: 'myprofile', element: <MyProfile /> },
      { path: 'organizations/enrich', element: <OrganizationsEnrichPage /> },
      { path: 'organizations/import', element: <OrganizationsImportPage /> },
      { path: 'organizations', element: <OrganizationsPage /> },
      { path: 'organization/:id', element: <OrganizationPage /> },
      { path: 'planning-shifts', element: <PlanningPage /> },
      { path: 'projects', element: <ProjectsPage /> },
      { path: 'project/:id', element: <ProjectDetailPage /> },
      { path: 'project', element: <ProjectCreatePage /> },
      { path: 'reporting', element: <ReportsPage /> },
      { path: 'review', element: <ReviewPage /> },
      { path: 'settings-main/:type', element: <SettingsMainPage /> },
      { path: 'settings-main/:type/:id', element: <SettingsMainPage /> },
      { path: 'shift/:id', element: <ShiftDetailPage /> },
      { path: 'shift', element: <ShiftCreatePage /> },
      { path: 'time-tracking', element: <TimeTrackerPage /> },
      { path: 'workers/enrich', element: <WorkersEnrichPage /> },
      { path: 'workers/invite', element: <WorkersInvitePage /> },
      { path: 'workers/import', element: <WorkersImportPage /> },
      { path: 'agreements', element: <AgreementsPage /> },
      { path: 'workers', element: <WorkersPage /> },
      { path: 'worker/:id', element: <WorkerPage /> },
      { path: 'worker/:id/calendar', element: <WorkerCalendar /> },
    ];

    const commonRouters = [
      { path: 'clients/confirmation', element: (<ErrorBoundary><ConfirmationPage /></ErrorBoundary>) },
      { path: 'clients/password/edit', element: (<ErrorBoundary><ForgotPWDConfirmPage /></ErrorBoundary>) },
      { path: 'managers/password/edit', element: (<ErrorBoundary><ForgotPWDConfirmPage /></ErrorBoundary>) },
      { path: 'workers/password/edit', element: (<ErrorBoundary><ForgotWorkerPWDConfirmPage /></ErrorBoundary>) },
    ];
    const publicRouters = [
      { path: 'login', element: <LoginPage /> },
      { path: 'signup', element: <SignupPage /> },
      { path: 'forgot', element: <ForgotPWDPage /> },
      { path: 'managers/invitation/accept', element: <ManagerInvitation /> },
    ];

    const redirectPath = profile.email ? 'dashboard' : 'login';

    return (
      <App currentLang={currentLang}>
        <Routes>
          {/* Common routers with/without auth */}
          {commonRouters.map((commonRoute) => (
            <Route key={commonRoute.path} path={commonRoute.path} element={commonRoute.element} />
          ))}

          {/* Public routers without auth */}
          <Route
            element={(
              <PublicRoutes
                currentLang={currentLang}
                profile={profile}
                userType={userType}
              />
            )}
          >
            {publicRouters.map((publicRouter) => (
              <Route key={publicRouter.path} path={publicRouter.path} element={publicRouter.element} />
            ))}
          </Route>

          {/* Protected routers, with auth */}
          <Route
            element={(
              <ProtectedRoutes
                currentLang={currentLang}
                isAuthenticated={profile.email}
              />
            )}
          >
            {protectedRouters.map((protectedRoute) => (
              <Route key={protectedRoute.path} path={protectedRoute.path} element={protectedRoute.element} />
            ))}
          </Route>

          <Route path="/" element={(<Navigate to={`/${currentLang}/${redirectPath}`} replace />)} />
          <Route path="*" element={(<NotFoundPage currentLang={currentLang} />)} />
        </Routes>
      </App>
    );
  }
}

RouterContainer.displayName = 'RouterContainer';
RouterContainer.childContextTypes = {
  localization: PropTypes.object.isRequired,
  currentLang: PropTypes.string.isRequired,
  langs: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  isExternal: PropTypes.bool.isRequired,
  isExternalBlocked: PropTypes.bool.isRequired,
  isInternalBlocked: PropTypes.bool.isRequired,
  isInternalBlockedFletcher: PropTypes.bool.isRequired,
  isInternalBlockedSwzzorg: PropTypes.bool.isRequired,
  isExternalExcellentFlex: PropTypes.bool.isRequired,
  isYzorgManagers: PropTypes.bool.isRequired,
};
RouterContainer.propTypes = {
  currentLang: PropTypes.string.isRequired,
  translates: PropTypes.object.isRequired,
  langs: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  isExternal: PropTypes.bool.isRequired,
  isExternalBlocked: PropTypes.bool.isRequired,
  isInternalBlocked: PropTypes.bool.isRequired,
  isInternalBlockedFletcher: PropTypes.bool.isRequired,
  isInternalBlockedSwzzorg: PropTypes.bool.isRequired,
  isExternalExcellentFlex: PropTypes.bool.isRequired,
  isYzorgManagers: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(RouterContainer);
